<template>
  <div class="content">
    <!--[main]-->
    <div class="main">
      <PageHeader :pageName="pageName" />
      <section class="section">
        <div class="row">
          <div class="col-8">
            <Panel type="board">
              <template v-slot:headline>検索条件</template>
              <template v-slot:body>
                <div class="search">
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>お名前</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="name"
                                  v-maxlength
                                  maxlength="41"
                                  v-model="searchForm.name"/>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>フリガナ</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="nameKana"
                                  v-model="searchForm.nameKana"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>メールアドレス</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="email"
                                  v-email
                                  v-trim
                                  v-maxlength
                                  maxlength="50"
                                  v-model="searchForm.email"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>電話番号</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="tel"
                                  name="telephone"
                                  v-trim
                                  v-number
                                  v-maxlength
                                  maxlength="14"
                                  v-model="searchForm.telephone"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>user ID</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-icon form-icon-search">
                                <input
                                  class="form-control form-icon-input"
                                  type="text"
                                  name="number"
                                  v-trim
                                  v-maxlength
                                  maxlength="11"
                                  v-model="searchForm.number"
                                />
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>誕生日</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-group">
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="birthDayStart"
                                      v-model="searchForm.birthDayStart"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                                <div class="form-group-item">
                                  〜
                                </div>
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="birthDayEnd"
                                      v-model="searchForm.birthDayEnd"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>登録日</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-group">
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="startDate"
                                      v-model="searchForm.startDate"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                                <div class="form-group-item">
                                  〜
                                </div>
                                <div class="form-group-item">
                                  <div class="form-icon form-date">
                                    <Flatpickr
                                      class="form-control form-icon-input form-date-input"
                                      name="endDate"
                                      v-model="searchForm.endDate"
                                      :config="config.noLimit"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                      <div class="col" v-if="childList.length > 1">
                        <FormRow>
                          <template v-slot:label>参加イベント</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <div class="form-select">
                                <select class="form-control form-select-input" name="eventId" v-model="searchForm.eventId">
                                  <option value="">選択してください</option>
                                  <option v-for="item in childList" :key="item.id" :value="item.directory">{{ item.name }}</option>
                                </select>
                              </div>
                            </div>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                  <div class="search-row" v-if="isSearchFormOpened && isBtnMoreShown && !useTicketFlag">
                    <div class="row">
                      <div class="col">
                        <FormRow>
                          <template v-slot:label>顧客選択</template>
                          <template v-slot:content>
                            <div class="form-content-row">
                              <ul class="checkboxRadio mb-10 mt-10">
                                <li v-for="item in customerTypes" :key="item.value" class="checkboxRadio-item">
                                  <label class="form-radio">
                                    <input
                                      class="form-radio-input"
                                      type="radio"
                                      name="type"
                                      :value="item.value"
                                      v-model="searchForm.type"
                                    />
                                    <span class="form-radio-label">{{ item.label }}</span>
                                  </label>
                                </li>
                              </ul>
                            </div>
                            <transition name="fade" v-if="isCpmType">
                              <div class="form-content-row">
                                <ul class="listGrid">
                                  <li v-for="item in userCpmStatus" :key="item.value" class="listGrid-item">
                                    <label class="form-check form-check-btn d-inline-block">
                                      <input
                                        class="form-check-input"
                                        type="checkbox"
                                        name="isActiveCpm"
                                        :value="item.value"
                                        v-model="searchForm.isActiveCpm"
                                      />
                                      <span class="form-check-label">{{ item.label }}</span>
                                    </label>
                                  </li>
                                </ul>
                              </div>
                            </transition>
                          </template>
                        </FormRow>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-slot:contentFooter>
                <ul class="listGrid justify-content-end">
                  <li class="listGrid-item" v-if="isBtnMoreShown && !useTicketFlag">
                    <button class="btn btn-white" type="button" @click="addSearch">{{ isSearchFormOpened ? '閉じる' : '高度な検索' }}</button>
                  </li>
                  <li class="listGrid-item pos-end">
                    <ul class="listGrid">
                      <li class="listGrid-item">
                        <button class="btn btn-white" type="button" @click="resetSearch">リセット</button>
                      </li>
                      <li class="listGrid-item">
                        <button class="btn btn-main" type="button" @click="search">検索</button>
                      </li>
                    </ul>
                  </li>
                </ul>
              </template>
            </Panel>
          </div>
          <div class="col-4">
            <Panel type="board" :padding="false" :center="true">
              <template v-slot:body>
                <NumDisplay :position="customPosition" color="dark">
                  <template v-slot:title>顧客累計数</template>
                  <template v-slot:num>{{ customerTotal | comma }}</template>
                  <template v-slot:sup>件</template>
                  <template v-slot:sub>※退会は含まない</template>
                </NumDisplay>
              </template>
            </Panel>
            <Panel type="board" :padding="false" :center="true" v-if="isUseCpmParentEvent">
              <template v-slot:body>
                <NumDisplay position="start" color="dark">
                  <template v-slot:title>user ID累計数</template>
                  <template v-slot:num>{{ customerCpmTotal | comma }}</template>
                  <template v-slot:sup>件</template>
                  <template v-slot:sub>※すべての顧客</template>
                </NumDisplay>
              </template>
            </Panel>
          </div>
        </div>
      </section>
      <section class="section">
        <div class="row">
          <div class="col">
            <Panel type="board" :padding="false">
              <template v-slot:body>
                <div class="scrollX">
                  <TableWithCheckbox
                    :data-table="dataTable"
                    :labels="labels"
                  />
                </div>
              </template>
              <template v-slot:footer>
                <PaginationLog
                  ref="pagination"
                  :listLength="customerCount"
                  :modulePath="modulePath"
                  :subdomain="subdomain"
                  :searchConditions="searchConditions"
                ></PaginationLog>
              </template>
            </Panel>
          </div>
        </div>
      </section>
    </div>
    <!--[/main]-->
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Flatpickr from 'vue-flatpickr-component';
//mixins
import flatpickr from '@/mixins/plugin/flatpickr';
import nav from '@/mixins/nav/user';
import search from '@/mixins/plugin/search';
//component
import FormRow from '@/components/FormRow.vue';
import NumDisplay from '@/components/NumDisplay.vue';
import TableWithCheckbox from '@/components/TableWithCheckbox.vue';
import PaginationLog from '@/components/PaginationLog.vue';
//helpers
import { formatLocalString, formatDateAndTime, breakLineWithComma, endline } from '@/helpers/formatData';
//constants
import { StatusConstants } from '@/constants/status';

export default {
  name: 'ShopUser',
  data: function() {
    return {
      pageName: '顧客管理',
      modulePath: 'customer/getCustomerList',
      searchConditions: {},
      selectedUsers: [],
      storeModule: "customer",
      searchFields: ['name', 'email', 'number', 'type', 'isActiveCpm', 'telephone', 'startDate', 'endDate', 'birthDayStart', 'birthDayEnd', 'nameKana', 'eventId'],
      isSearchFormOpened: false,
    };
  },
  mixins: [nav, search, flatpickr],
  components: {
    FormRow,
    NumDisplay,
    TableWithCheckbox,
    PaginationLog,
    Flatpickr,
  },
  computed: {
    ...mapGetters({
      infor: 'auth/infor',
      customerList: 'customer/customerList',
      customerCount: 'customer/customerCount',
      customerTotal: 'customer/customerTotal',
      customerCpmTotal: 'customer/customerCpmTotal',
      isUseCpmParentEvent: 'event/isUseCpmParentEvent',
      subdomain: 'auth/commonSubdomain',
      useTicketFlag: 'event/useTicketFlag',
      useOmise: 'event/useOmise',
      identityVerification: 'event/identityVerification',
      childList: 'customer/childList',
      parentEventDetail: 'event/parentEventDetail',
      hasSmsParentEvent: 'event/hasSmsParentEvent',
    }),
    initialSearchConditions() {
      return  {
        type: StatusConstants.customerType.all.value,
        searchableDirectory: this.searchForm?.eventId || '',
      };
    },
    labels() {
      return [
        { key: 'number', name: 'user ID', tdClass: 'pre-line' },
        { key: 'name', name: 'お名前', tdClass: 'pre-line' },
        { key: 'email', name: this.hasSmsParentEvent ? 'メールアドレス/携帯電話番号' : 'メールアドレス', tdClass: 'pre-line' },
        { key: 'loginDate', name: '最終ログイン' },
        { key: 'balance', name: '残高' },
        { isButtonColumn: true },
      ];
    },
    userCpmStatus() {
      return Object.values(StatusConstants.userCpmStatus);
    },
    isCpmType() {
      return [StatusConstants.customerType.cpm.value].includes(this.searchForm?.type);
    },
    customerTypes() {
      return Object.values(StatusConstants.customerType).slice(0, 3);
    },
    customPosition() {
      return this.isUseCpmParentEvent ? 'end' : 'center';
    },
    isBtnMoreShown() {
      return this.isUseCpmParentEvent || this.useOmise || this.identityVerification;
    },
    dataTable() {
      return this.customerList.map(item => {
        return {
          ...item,
          number: {
            content: item?.hasSwitchCpm ? breakLineWithComma((item?.number ? (item.number + ',') : '') + item?.numberPut) : item?.isCpm ? item?.number : item?.numberPut,
            color: (item?.hasSwitchCpm && item.isActive === 1) ? 'text-red' : !item.isCpm ? 'tox' : item.isActive === 1 ? 'text-red' : 'text-blue',
          },
          name: endline(item?.nameSei + ' ' + item?.nameMei, 21),
          email: !this.hasSmsParentEvent ? item?.email : (item?.email && item?.mobilephone ? breakLineWithComma(item.email + ',' + item?.mobilephone) : (item?.email || item?.mobilephone)),
          balance: '¥' + formatLocalString(this.useTicketFlag ? item?.balanceTicket : item?.balance),
          loginDate: formatDateAndTime(item.loginDate),
          buttonLinks: [
            { content: '詳細', class: 'btn-bd-main', routeName: 'ShopUserDetail', params: { id: item.id } },
          ],
        };
      });
    },
    customParams() {
      return {
        subdomain: this.subdomain,
        limit: 15,
        offset: 1,
      }
    },
  },
  watch: {
    'searchForm.type'(value) {
      if ([StatusConstants.customerType.all.value, StatusConstants.customerType.normal.value].includes(value)) {
        this.searchForm.isActiveCpm = [];
      } else if (!value) {
        this.searchForm.isActiveCpm = [];
        this.searchForm.type = StatusConstants.customerType.all.value;
      }
    },
    isUseCpmParentEvent(value) {
      if (value) {
        this.getCustomerTotal('all');
      }
    }
  },
  methods: {
    getCustomerTotal(type) {
      if (type !== 'all' || this.isUseCpmParentEvent) {
        this.$store.dispatch('customer/getCustomerTotal', { ...this.customParams, type: StatusConstants.customerType[type].value })
      }
    },
    addSearch() {
      this.isSearchFormOpened = !this.isSearchFormOpened;
    },
  },
  async mounted() {
    const loading = this.$loading.show();
    Promise.all([
      this.getCustomerTotal('normal'),
      this.getCustomerTotal('all'),
      this.$store.dispatch('customer/getChildList', { subdomain: this.subdomain }),
    ]).finally(() => {
      this.$loading.clear(loading);
    });
  },
};
</script>
